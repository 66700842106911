import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { customer, sale, auth } from './'
import thunk from 'redux-thunk';
const rootReducer = combineReducers({ customer, sale, auth });
const persistConfig = {
    key: 'root',
    storage, whitelist: ['auth']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(rootReducer, compose(
    applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
));

let persistor = persistStore(store)
export { store, persistor };