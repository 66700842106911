import axios from 'axios'
import { async } from 'q';

//actions
const SHOW_ALL_SALES = 'SHOW_ALL_SALES'
const SELECTED_SALE = 'SELECTED_SALE'
const ADD_SALE = 'ADD_SALE'
const UPDATE_SALE = 'UPDATE_SALE'

export function saveSaleApi(sale) {

    return async function (dispatch) {

        try {

            if (sale.id) {//update exiting sale  
                console.log('post', JSON.stringify(sale, null, 2))
                const response = await axios.put(process.env.REACT_APP_API + '/api/sales/' + sale.id, sale);
                return dispatch(updateSale(response.data));
            } else {//add new sale 

                const response = await axios.post(process.env.REACT_APP_API + '/api/sales', sale);
                console.log('post', JSON.stringify(response.data, null, 2))

                return dispatch(addSale(response.data));
            }

            // console.log((`{ "barcode": 1022,"sale":${JSON.stringify(sale)} }`))


        }
        catch (error) {
            console.log(error);
        }
    }
}
export function getSaleDetails(storeId, id) {

    return async function (dispatch) {

        try {


            const response = await axios(process.env.REACT_APP_API + `/api/sales/detail/${storeId}/${id}`);

            return dispatch(selectedSale(response.data));



        }
        catch (error) {
            console.log(error);
        }
    }
}

//{id: 23, date: null, customerId: 1, saleDate: "2019-06-09T00:00:00", totalAmt: 150}
export function fetchAllSales(storeId) {
    return async function (dispatch) {
        try {
            const response = await axios(process.env.REACT_APP_API + '/api/sales/' + storeId);
            const sauce = response.data;
            // console.log(JSON.stringify(sauce, null, 2))
            dispatch(showAllSales(sauce));
        }
        catch (error) {
            return console.log(error);
        }
    };


}
export function showAllSales(sales) {
    return {
        type: SHOW_ALL_SALES,
        sales
    }
}
export function selectedSale(sale) {
    return {
        type: SELECTED_SALE,
        sale
    }
}
export function updateSale(sale) {
    return {
        type: UPDATE_SALE,
        sale
    }
}
export function addSale(sale) {
    return {
        type: ADD_SALE,
        sale
    }
}
//action type
const initialState = { sales: [], sale: {} }
export const sale = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ALL_SALES:
            return {
                ...state,
                sales: action.sales
            }

        case SELECTED_SALE:

            return { ...state, sale: action.sale };

        //Object.assign({}, state, { customer: state.sales.find(c => c.barcode == 1005) })
        case UPDATE_SALE:
            return Object.assign({}, state, {
                sales: state.sales.map((c, index) => {
                    if (c.id === action.sale.id) {
                        return action.sale

                    }
                    return c
                })
            })
        case ADD_SALE:

            return {
                ...state, sales: [...state.sales, action.sale]
            }


        default:
            return state;
    }

}
