import axios from 'axios'
import decodeJWT from 'jwt-decode';

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

function requestLogin(creds) {
    return {
        type: LOGIN_REQUEST,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: '',
        creds
    }
}

function receiveLogin() {
    return {
        type: LOGIN_SUCCESS,
        isFetching: false,
        isAuthenticated: true,
        user: currentUser()
    }
}

function loginError(message) {
    return {
        type: LOGIN_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: message
    }
}
// Calls the API to get a token and
// dispatches actions along the way
export function loginUser(creds) {

    let config = {
        method: 'POST',
        url: process.env.REACT_APP_API + '/api/auth/login/',
        headers: { 'Content-Type': 'application/json' },
        data: creds
    }

    return dispatch => {
        // We dispatch requestLogin to kickoff the call to the API
        // console.log(JSON.stringify(config, null, 2))
        dispatch(requestLogin(creds))
        return axios(config)
            .then((response) => {
                //console.log(JSON.stringify(response, null, 2))
                if (response.status !== 200) {
                    // If there was a problem, we want to
                    // dispatch the error condition
                    dispatch(loginError('no 200'))
                    return Promise.reject(response.data)
                } else if (response.status === 200) {
                    // If login was successful, set the token in local storage
                    // history.push('/');

                    localStorage.setItem('accessToken', JSON.stringify(response.data.accessToken))
                    // Dispatch the success action

                    dispatch(receiveLogin())


                }
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 400 || error.response.status === 404)
                        dispatch(loginError('Incorrect Username Or Password'))
                    else if (error.response.status === 500)
                        dispatch(loginError('Server not responding try again later:500'))

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js 
                    dispatch(loginError('Server not responding try again later:'))
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }

                console.log(config);
            })
    }
}
// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
        isFetching: true,
        isAuthenticated: true
    }
}

function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false

    }
}


// Logs the user out
export function logoutUser() {
    return dispatch => {
        dispatch(requestLogout())
        localStorage.removeItem('accessToken')
        dispatch(receiveLogout())
    }
}
function currentUser() {
    if (localStorage.getItem("accessToken") === null) {
        return null
    }

    const decoded = decodeJWT(localStorage.getItem('accessToken'));

    return decoded

}
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export const auth = (state = {
    user: currentUser(),
    isFetching: false,
    errorMessage: '',
    isAuthenticated: localStorage.getItem('accessToken') ? true : false
}, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isAuthenticated: false,

                user: action.creds
            })
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                errorMessage: '',
                user: action.user
            })
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.errorMessage
            })
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: '',
                user: null
            })
        default:
            return state
    }
}
