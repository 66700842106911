import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux'
import { history } from './components/history'

// import { renderRoutes } from 'react-router-config';
import './App.scss';
import PrivateRoute from './components/PrivateRoute'
import { loginUser, logoutUser } from './redux/authReducer'
//import Logout from './components/Logout'
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  render() {
    const { dispatch, isAuthenticated, errorMessage, isFetching } = this.props

    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login isFetching={isFetching} errorMessage={errorMessage}
              onLoginClick={creds => dispatch(loginUser(creds))} {...props} />} />
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

            <PrivateRoute

              path="/"
              name="Home"
              component={DefaultLayout}
              isAuthenticated={isAuthenticated}
            />
            {/* <Route path="/" name="Home" component={DefaultLayout} /> */}
            {/* <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} /> */}
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}
// {!isAuthenticated &&
//   <Login
//     errorMessage={errorMessage}
//     onLoginClick={creds => dispatch(loginUser(creds))}
//   />
// }

// {isAuthenticated &&
//   <Logout onLogoutClick={() => dispatch(logoutUser())} />
// }
// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { auth } = state

  const { isAuthenticated, errorMessage, isFetching } = auth

  return {

    isAuthenticated,
    errorMessage, isFetching
  }
}

export default connect(mapStateToProps)(App)
