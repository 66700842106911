import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { store, persistor } from './redux/configureStore'
import { PersistGate } from 'redux-persist/integration/react'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authentication from 'react-azure-adb2c';
authentication.initialize({
    instance: 'https://login.microsoftonline.com/tfp/',
    tenant: 'fealtyb2c.onmicrosoft.com',
    signInPolicy: 'B2C_1_fealty_signup',
    applicationId: '3e5f7460-9248-4be1-b5eb-95a43d392226',
    cacheLocation: 'sessionStorage',
    scopes: ['https://fealtyb2c.onmicrosoft.com/api/user_impersonation'],
    redirectUri: 'http://localhost:3000',
    postLogoutRedirectUri: window.location.origin,
});

// authentication.run(() => {
ReactDOM.render(
    <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <App />
        {/* </PersistGate> */}
    </Provider>

    , document.getElementById('root')); serviceWorker.unregister();
//  });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

