import axios from 'axios'

//actions
const SHOW_ALL_CUSTOMERS = 'SHOW _ALL_CUSTOMERS'
const SELECTED_CUSTOMER = 'SELECTED_CUSTOMER'
const ADD_CUSTOMER = 'ADD_CUSTOMER'
const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

export function saveCustomerApi(customer) {

    return async function (dispatch) {

        try {
            if (customer.customerId) {
                const response = await axios.put(process.env.REACT_APP_API + '/api/customers/' + customer.customerId, customer);

                return dispatch(updateCustomer(response.data));
            } else {
                const response = await axios.post(process.env.REACT_APP_API + '/api/customers/', customer);
                return dispatch(addCustomer(response.data));
            }

        }
        catch (error) {
            console.log(error);
        }
    }
}
export function fetchAllCustomers(storeId) {
    return async function (dispatch) {
        try {
            const response = await axios(process.env.REACT_APP_API + '/api/customers/GetCustomers/' + storeId);
            const sauce = response.data;
            dispatch(showAllCustomers(sauce));

        }
        catch (error) {
            return console.log(error);
        }
    };


}
export function showAllCustomers(customers) {
    return {
        type: SHOW_ALL_CUSTOMERS,
        customers
    }
}
export function selectedCustomer(customerId) {
    return {
        type: SELECTED_CUSTOMER,
        customerId
    }
}
export function updateCustomer(customer) {
    return {
        type: UPDATE_CUSTOMER,
        customer
    }
}
export function addCustomer(customer) {
    return {
        type: ADD_CUSTOMER,
        customer
    }
}
//action type
const initialState = { customers: [] }
export const customer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ALL_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            }

        case SELECTED_CUSTOMER:
            return { ...state, customer: state.customers.find(c => c.customerId === action.customerId) };

        //Object.assign({}, state, { customer: state.customers.find(c => c.barcode == 1005) })
        case UPDATE_CUSTOMER:
            return Object.assign({}, state, {
                customers: state.customers.map((c, index) => {
                    if (c.customerId === action.customer.customerId) {
                        return action.customer

                    }
                    return c
                })
            })
        case ADD_CUSTOMER:
            return {
                ...state, customers: [...state.customers, action.customer]
            }


        default:
            return state;
    }

}
